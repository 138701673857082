<template>
  <v-app-bar
    absolute
    color="transparent"
    flat
    height="85"
  >
    <v-container class="px-0 text-right d-flex align-center">
      <div
        style="height:50px; cursor: pointer;"
        @click="goHome"
      >
        <!-- <v-img
          :src="require(`@/assets/logo-mini.png`)"
          width="50"
        /> -->
      </div>
      <v-spacer />

      <v-menu
        bottom
        left
        offset-y
        origin="top right"
        transition="scale-transition"
      >
        <template v-slot:activator="{ attrs, on }">
          <v-btn
            class="ml-2"
            min-width="0"
            text
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>mdi-flag</v-icon>
          </v-btn>
        </template>
        <v-list
          :tile="false"
          nav
        >
          <div>
            <app-bar-item @click.native="clickLang('en')">
              EN
            </app-bar-item>
            <app-bar-item @click.native="clickLang('de')">
              DE
            </app-bar-item>
            <app-bar-item @click.native="clickLang('it')">
              IT
            </app-bar-item>
          </div>
        </v-list>
      </v-menu>

      <v-btn
        v-if="account"
        min-height="48"
        min-width="40"
        text
        to="/app"
      >
        <v-icon
          :left="$vuetify.breakpoint.mdAndUp"
          size="20"
          v-text="'mdi-table'"
        />

        <span
          class="hidden-sm-and-down"
          v-text="$t('dashboard')"
        />
      </v-btn>
      <div v-else>
        <v-btn
          v-for="(item, i) in items"
          :key="i"
          :to="item.to"
          min-height="48"
          min-width="40"
          text
        >
          <v-icon
            :left="$vuetify.breakpoint.mdAndUp"
            size="20"
            v-text="item.icon"
          />

          <span
            class="hidden-sm-and-down"
            v-text="$t(item.text)"
          />
        </v-btn>
      </div>
      <a href="https://evalus.at/datenschutz/" target="_blank">
        <v-btn
          min-height="48"
          min-width="40"
          text
        >
          <v-icon
            :left="$vuetify.breakpoint.mdAndUp"
            size="20"
            v-text="'mdi-fingerprint'"
          />

          <span
            class="hidden-sm-and-down"
            v-text="$t('privacy-policy')"
          />
        </v-btn>
      </a>
    </v-container>
  </v-app-bar>
</template>

<script>
  import i18n, { changeLang } from '@/i18n'
  import { mapGetters } from 'vuex'
  import { VHover, VListItem } from 'vuetify/lib'

  export default {
    name: 'PagesCoreAppBar',

    components: {
      AppBarItem: {
        render (h) {
          return h(VHover, {
            scopedSlots: {
              default: ({ hover }) => {
                return h(
                  VListItem,
                  {
                    class: {
                      'black--text': !hover,
                      'white--text secondary elevation-12': hover,
                    },
                    props: {
                      activeClass: '',
                      dark: hover,
                      link: true,
                      ...this.$attrs,
                    },
                  },
                  this.$slots.default,
                )
              },
            },
          })
        },
      },
    },

    data: () => ({
      items: [
        {
          icon: 'mdi-account-multiple-plus',
          text: 'login',
          to: '/login',
        },
      ],
    }),
    computed: {
      ...mapGetters({
        account: 'account',
      }),
    },
    methods: {
      goHome () {
        this.$router.push('/', () => {})
      },
      changeLanguage (lang) {
        i18n.locale = lang
        changeLang(lang)
      },
      clickLang (lang) {
        this.changeLanguage(lang)
      },
    },
  }
</script>
